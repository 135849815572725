import React, { useState, useEffect } from "react";
import { Button} from "./ui/button";
import { Card } from "./ui/card";
import backgroundImage from "../images/background1.webp"; // Imported the background image
import caldocsLogo from "../images/logo.png"; // Imported the Calance logo
import calanceLogo from "../images/anchor.webp";
import Cookies from 'js-cookie';

const backendUrl = import.meta.env.VITE_BACKEND_URL;
const hrmsBaseUrl = import.meta.env.VITE_HRMS_BASE_API_URL;
console.log("backendUrl->", backendUrl);
console.log("hrmsBaseUrl->", hrmsBaseUrl);

const Login = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  useEffect(() => {
    const jwtToken = Cookies.get('jwtToken');
    console.log("jwtToken->", jwtToken);
    if (jwtToken) {
      window.location.href = `${hrmsBaseUrl}/view/welcome`;
    }
  });

  const handleLogin = async () => {
    setLoading(true);
    setError("");

    try {
      const response = await fetch(backendUrl + "/auth/login", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (response.ok) {
        const data = await response.json();
        const redirectUrl = data?.data?.url;
        if (redirectUrl) {
          window.location.href = redirectUrl;
        } else {
          setError("Redirect URL not found.");
        }
      } else {
        setError("Failed to fetch the redirect URL.");
      }
    } catch (error) {
      setError("An error occurred during login.");
      console.error("Error:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div
      className="flex h-screen bg-cover"
      style={{ backgroundImage: `url(${backgroundImage})` }}
    >
      <Card className="bg-slate-200 bg-opacity-90 p-2 rounded-none shadow-lg w-full max-w-[30%] ml-12">
        <div className="bg-slate-300 bg-opacity-70 mb-4 -m-2 shadow-lg">
          <div className="flex items-center mb-10">
            <img
              src={caldocsLogo}
              alt="CalDocs Logo"
              className="h-8 pl-2 pt-2 w-25 mr-2"
            />
          </div>
          <h2 className="text-center text-md font-800 pb-3">
            Select Authentication Provider
          </h2>
        </div>

        <Button
          onClick={handleLogin}
          disabled={loading}
          className="flex justify-start items-center w-full py-7 text-sm bg-white text-gray-500 font-semibold rounded-lg shadow-md transition hover:bg-gray-100"
        >
          <img src={calanceLogo} alt="Calance Logo" className="h-6 w-6 mr-3" />
          {loading ? "Logging in..." : "Calance AAD"}
        </Button>
        {error && <p className="text-red-500 mt-4 text-center">{error}</p>}
      </Card>
    </div>
  );
};

export default Login;
